<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar data diaria</h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga de archivo .DBF o .XLSX</h3>
        </b-card-header>
        <b-card-body class="custom-card-body">
          <form class="custom-form" @submit.prevent="save">
            <div
              class="d-flex flex-column flex-md-row align-items-md-center w-50"
            >
              <label for="input-date" class="mb-0 mt-0 mr-3">Fecha</label>
              <b-input-group>
                <FormDatepicker
                  label-id="input-date"
                  :show-calendar="showDatePicker"
                  :min="$store.getters['app/getStartDate']"
                  :max="$store.getters['app/getAvailableDate']"
                  :hideHeader="true"
                  :error="$v.selectedDate.$error"
                  @show="showDatePicker = true"
                  @hidden="showDatePicker = false"
                  v-model="selectedDate"
                  class="w-100"
                />
              </b-input-group>

              <form-error
                message="Fecha de carga es requerida"
                v-if="$v.selectedDate.$error && !$v.selectedDate.required"
              ></form-error>
            </div>
            <div>
              <file-input
                @onChange="onChange"
                idFile="voucher-batch-file"
                :acceptFile="['.dbf', '.xlsx']"
              />

              <form-error
                message="El archivo es requerido"
                v-if="$v.file.$error && !$v.file.required"
              ></form-error>
            </div>
            <div class="form-group-buttons text-right w-100">
              <router-link
                :to="{ name: 'daily-voucher-batch-list' }"
                class="button button-light"
              >
                Cancelar
              </router-link>
              <button class="button button-primary" type="submit">
                Procesar
              </button>
            </div>
            <div class="form-errors mt-3" v-if="errors.length">
              <table class="custom-table w-100">
                  <thead>
                    <tr class="text-left">
                      <th>{{ `Errores: ${errors.length}` }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, i) in errors" :key="i">
                      <td class="pl-md-5">
                        <span
                          class="d-inline-block w-75"
                          >{{ e }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, Util, FormError, FormDatepicker, FileInput } from "wize-admin";

import { VoucherBatchService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  components: { FileInput, FormDatepicker, FormError },
  data() {
    return {
      file: null,
      selectedDate: this.$store.getters["app/getAvailableDate"],
      errors: [],
      showDatePicker: false,
    };
  },
  validations: {
    file: { required },
    selectedDate: { required },
  },
  methods: {
    onChange(file) {
      this.errors = [];
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
        date: this.selectedDate,
      });
      try {
        const payload = (await VoucherBatchService.uploadDaily(data)).payload;
        if (!payload.success && payload.messages) {
          this.errors = payload.messages;
          return;
        }
        Alert.success("Se ha realizado la carga correctamente");
        this.$router.push({ name: "daily-voucher-batch-list" });
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>
