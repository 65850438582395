<template>
  <div class="dashboard-page">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">{{ business.name }}</h1>

      <section class="dashboard-container">
        <div class="dashboard-card"></div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      business: "app/getBusiness",
    }),
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/Dashboard.styl"
</style>
