<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Procesos diarios</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-end align-items-center justify-content-lg-start"
            >
              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por procesado por"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="d-flex flex-column flex-md-row align-items-end text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'daily-process-load' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Procesar libro diario</b-button
                >

                <b-button
                  :to="{ name: 'daily-process-load-range' }"
                  variant="none"
                  class="button button-primary mt-2 mt-md-0 ml-md-2"
                  >Procesar libro diario en rango</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha del libro</span>
            </div>
            <div>
              <span>Valor hallado</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.date"></span>
                </div>

                <div>
                  <span v-decimal:pen="o.foundValue"></span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options d-flex mt-3" v-if="o.status !== DailyProcessStatus.RUNNING.key">
                <a
                  href=""
                  class="d-block w-50"
                  @click="openCloseProcess(o)"
                  v-if="getOptions(o.status).includes('close')"
                  >Cerrar día</a
                >
                <a
                  href=""
                  class="d-block w-50"
                  @click="openDeleteProcess(o)"
                  v-if="getOptions(o.status).includes('delete')"
                  >Eliminar</a
                >
                <a
                  href=""
                  class="d-block w-50"
                  @click="downloadComparative(o.id)"
                  v-if="getOptions(o.status).includes('downloadComparative')"
                  >Descargar comparativo</a
                >
                <a
                  href=""
                  class="d-block w-50"
                  @click="downloadInventory(o.id)"
                  v-if="
                    getOptions(o.status).includes('downloadInventory') &&
                    !o.holiday
                  "
                  >Descargar libro detallado</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Valor final:</span
                    >
                    <span v-decimal:pen="o.finalValue"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Diferencia Soles:</span
                    >
                    <span
                      :class="{
                        'text-error': o.diffValue < 0,
                      }"
                      v-decimal:pen="o.diffValue"
                    ></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Procesado por:</span
                    >
                    <span>
                      {{ o.createdBy }}
                    </span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Fecha y hora de procesamiento:</span
                    >
                    <span v-local-date:hour="o.createdAt"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      o.status === DailyProcessStatus.CLOSED.key && o.holiday
                        ? "NO LABORABLE"
                        : getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Fecha del libro</th>
              <th>Valor hallado</th>
              <th>Valor final</th>
              <th>Diferencia Soles</th>
              <th>Procesado por</th>
              <th>Fecha y hora de procesamiento</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
              <td class="text-center" v-decimal:pen="o.foundValue"></td>
              <td class="text-center" v-decimal:pen="o.finalValue"></td>
              <td
                :class="{ 'text-center': true, 'text-error': o.diffValue < 0 }"
                v-decimal:pen="o.diffValue"
              ></td>
              <td class="text-center">{{ o.createdBy }}</td>
              <td class="text-center" v-local-date:hour="o.createdAt"></td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  o.status === DailyProcessStatus.CLOSED.key && o.holiday
                    ? "NO LABORABLE"
                    : getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable
                  v-if="o.status !== DailyProcessStatus.RUNNING.key"
                >
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Cerrar día"
                      @click="openCloseProcess(o)"
                      v-if="getOptions(o.status).includes('close')"
                    />
                    <DropdownItem
                      text="Eliminar"
                      @click="openDeleteProcess(o)"
                      v-if="getOptions(o.status).includes('delete')"
                    />
                    <DropdownItem
                      text="Descargar comparativo"
                      @click="downloadComparative(o.id)"
                      v-if="
                        getOptions(o.status).includes('downloadComparative')
                      "
                    />
                    <DropdownItem
                      text="Descargar libro detallado"
                      @click="downloadInventory(o.id)"
                      v-if="
                        getOptions(o.status).includes('downloadInventory') &&
                        !o.holiday
                      "
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Util,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
} from "wize-admin";
import fileDownload from "js-file-download";

import { Constants as LocalConstants } from "@/core/utils";

import { DownloadService, ProcessService } from "@/core/services";
import { ConfirmationModalMixin, PaginationMixin } from "wize-admin";

export default {
  components: {
    SearchBar,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      DailyProcessStatus: LocalConstants.DailyProcessStatus,
      selectedOption: null,
    };
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  methods: {
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.DailyProcessStatus);
    },
    getOptions(status) {
      let val = [];
      if (status === LocalConstants.DailyProcessStatus.FINISHED.key)
        val = ["delete", "close", "downloadComparative", "downloadInventory"];
      else if (status === LocalConstants.DailyProcessStatus.CLOSED.key)
        val = ["downloadComparative", "downloadInventory"];
      return val;
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "date,desc",
          search: this.search,
        };
        const response = await ProcessService.listDaily(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openDeleteProcess(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    openCloseProcess(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cerrar proceso diario",
        description:
          "Una vez cerrado el día, no se podrá realizar ninguna otra acción",
        acceptText: "Aceptar",
        action: "close",
      });
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteProcess();
      else if (this.confirmationModal.action === "close") this.closeProcess();
    },
    async deleteProcess() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await ProcessService.deleteDaily(this.selectedOption.id))
          .payload;

        const obj = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (obj) {
          const index = this.list.indexOf(obj);
          this.list.splice(index, 1);
        }
        Alert.success("Proceso diario eliminado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async closeProcess() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await ProcessService.closeDaily(this.selectedOption.id))
          .payload;

        const process = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (process) {
          const index = this.list.indexOf(process);
          this.$set(this.list, index, resp);
        }
        await this.$store.dispatch("app/loadAvailableDate");
        await this.$store.dispatch("app/loadLastClosedDate");
        Alert.success("Proceso diario cerrado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadComparative(processId) {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.dailyComparative({ processId });
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadInventory(processId) {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.dailyInventory({ processId });
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
