var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.editing ? "Editar serie" : "Nueva serie"))])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos de la serie" : "Datos de la nueva serie")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo de comprobante *")]),_c('FormSelect',{attrs:{"id":"voucher","items":_vm.voucherTypes,"defaultOption":"Elegir tipo de comprobante","showError":_vm.$v.serie.voucherTypeId.$error},model:{value:(_vm.serie.voucherTypeId),callback:function ($$v) {_vm.$set(_vm.serie, "voucherTypeId", $$v)},expression:"serie.voucherTypeId"}}),(
                  _vm.$v.serie.voucherTypeId.$error &&
                  !_vm.$v.serie.voucherTypeId.required
                )?_c('form-error',{attrs:{"message":"Tipo de comprobante es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Código *")]),_c('FormInput',{attrs:{"id":"code","type":"text","maxlength":"5","placeholder":"Ingresar código","show-error":_vm.$v.serie.code.$error && !_vm.$v.serie.code.required},model:{value:(_vm.serie.code),callback:function ($$v) {_vm.$set(_vm.serie, "code", $$v)},expression:"serie.code"}}),(_vm.$v.serie.code.$error && !_vm.$v.serie.code.required)?_c('form-error',{attrs:{"message":"Código es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('FormInput',{attrs:{"id":"description","type":"text","maxlength":"200","placeholder":"Ingresar descripción","show-error":_vm.$v.serie.description.$error &&
                  !_vm.$v.serie.description.required},model:{value:(_vm.serie.description),callback:function ($$v) {_vm.$set(_vm.serie, "description", $$v)},expression:"serie.description"}}),(
                  _vm.$v.serie.description.$error &&
                  !_vm.$v.serie.description.required
                )?_c('form-error',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo de movimiento *")]),_c('FormSelect',{attrs:{"id":"type","items":_vm.serieTypes,"defaultOption":"Elegir tipo de movimiento","showError":_vm.$v.serie.type.$error},model:{value:(_vm.serie.type),callback:function ($$v) {_vm.$set(_vm.serie, "type", $$v)},expression:"serie.type"}}),(_vm.$v.serie.type.$error && !_vm.$v.serie.type.required)?_c('form-error',{attrs:{"message":"Tipo de movimiento es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-series-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }