<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargas diarias</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-end align-items-center justify-content-lg-start"
            >
              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por nombre de archivo o cargado por"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'daily-voucher-batch-load' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Cargar</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha</span>
            </div>
            <div>
              <span>Nombre de archivo</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.date"></span>
                </div>

                <div>
                  <span>{{ o.filename }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3" v-if="canDelete(o.date)">
                <a href="" @click.prevent="openDelete(o)">Eliminar</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cant. de documentos:</span
                    >
                    <span v-int="o.vouchersCount"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cant. de items:</span
                    >
                    <span v-int="o.itemsCount"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Valor total de compras:</span
                    >
                    <span v-decimal:pen="o.purchasesValueSum"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cargado por:</span
                    >
                    <span>{{ o.createdBy }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Nombre de archivo</th>
              <th>Cant. de documentos</th>
              <th>Cant. de items</th>
              <th>Valor total de compras</th>
              <th>Cargado por</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
              <td class="pl-5">{{ o.filename }}</td>
              <td class="text-center" v-int="o.vouchersCount"></td>
              <td class="text-center" v-int="o.itemsCount"></td>
              <td class="text-center" v-decimal:pen="o.purchasesValueSum"></td>
              <td class="text-center">{{ o.createdBy }}</td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable v-if="canDelete(o.date)">
                  <template slot="dropdown-content">
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="deleteBatch"
    />
  </div>
</template>

<script>
import {
  Alert,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
  PaginationMixin,
  ConfirmationModalMixin,
} from "wize-admin";
import { mapGetters } from "vuex";
import * as moment from "moment";

import { VoucherBatchService } from "@/core/services";

export default {
  components: {
    SearchBar,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
    };
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  computed: {
    ...mapGetters({
      lastClosedDate: "app/getLastClosedDate",
    }),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        await this.$store.dispatch("app/loadLastClosedDate");
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "date,desc",
          search: this.search,
        };

        const response = await VoucherBatchService.listDaily(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async deleteBatch() {
      this.closeConfirmationModal();
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await VoucherBatchService.deleteDaily(this.selectedOption.id)
        ).payload;

        const batch = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (batch) {
          const index = this.list.indexOf(batch);
          this.list.splice(index, 1);
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    canDelete(batchDate) {
      return moment(this.lastClosedDate).diff(batchDate) < 0;
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar" });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
