<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Borrar información</h1>
    </div>
    <div>
      <b-card tag="article" no-body class="custom-card custom-card--sm">
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Seleccione fecha</h3>
        </b-card-header>

        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="openDeleteProcess">
            <div class="form-section">
              <div class="form-group">
                <label for="start-date">Fecha inicio</label>
                <FormDatepicker
                  label-id="start-date"
                  :show-calendar="isOpenStartPicker"
                  :hideHeader="true"
                  @show="isOpenStartPicker = true"
                  @hidden="isOpenStartPicker = false"
                  v-model="startDate"
                  class="w-100"
                />
                <form-error
                  message="Fecha de inicio es requerida"
                  v-if="$v.startDate.$error && !$v.startDate.required"
                ></form-error>
              </div>
              <div class="form-group">
                <label for="end-date">Fecha final</label>
                <FormDatepicker
                  label-id="end-date"
                  :show-calendar="isOpenEndPicker"
                  :hideHeader="true"
                  @show="isOpenEndPicker = true"
                  @hidden="isOpenEndPicker = false"
                  v-model="endDate"
                  class="w-100"
                />

                <form-error
                  message="Fecha final es requerida"
                  v-if="$v.endDate.$error && !$v.endDate.required"
                ></form-error>
              </div>
              <div class="form-group mb-0">
                <div class="form-group">
                  <label for="type">Tipo de comprobante *</label>
                  <FormSelect
                    id="type"
                    v-model="type"
                    :items="types"
                    defaultOption="Elegir tipo de data"
                    :showError="$v.type.$error"
                  />
                  <form-error
                    message="Tipo de data es requerido"
                    v-if="$v.type.$error && !$v.type.required"
                  ></form-error>
                </div>
              </div>

              <div class="form-group-buttons text-right w-100">
                <button class="button button-primary" type="submit">
                  Realizar borrado
                </button>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  FormError,
  FormSelect,
  FormDatepicker,
  ConfirmationModal,
  ConfirmationModalMixin,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { BusinessService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      type: null,
      isOpenStartPicker: false,
      isOpenEndPicker: false,
      types: [
        LocalConstants.DataDeletionType.PROCESSES,
        LocalConstants.DataDeletionType.VOUCHERS,
        LocalConstants.DataDeletionType.FINALS,
        LocalConstants.DataDeletionType.ALL,
      ],
    };
  },
  mixins: [ConfirmationModalMixin],
  components: {
    FormDatepicker,
    FormSelect,
    FormError,
    ConfirmationModal,
  },
  validations: {
    startDate: { required },
    endDate: { required },
    type: { required },
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    confirmModal() {
      this.closeConfirmationModal();
      this.deleteProcess();
    },
    openDeleteProcess() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteProcess() {
      await this.$store.dispatch("app/loading", true);
      try {
        await BusinessService.deleteData({
          startDate: this.startDate,
          endDate: this.endDate,
          type: this.type,
        });
        Alert.success("Se eliminó la información correctamente");
        this.startDate = null;
        this.endDate = null;
        this.type = null;
        this.$v.$reset();
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
