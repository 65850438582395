<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    dialog-class="conversions-modal"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    content-class="modal-description"
  >
    <div
      class="d-flex justify-content-between aling-items-center conversions-modal-header-details pl-4 pr-4"
    >
      <div class="modal-title-left-primary my-auto px-2">
        Guías de conversión
      </div>
      <b-button class="btn-close text-white" @click="hide" aria-label="Close">
        <i class="ri-close-large-fill"></i>
      </b-button>
    </div>

    <div class="conversions-modal-body-details">
      <div class="modal-table-container separator">
        <div class="text-left">
          <span class="modal-title font-weight-bold">Guías de ingreso</span>
        </div>
        <div class="table-responsive">
          <table class="custom-table w-100">
            <thead>
              <tr>
                <th>Fecha</th>
                <th class="px-3">Serie</th>
                <th>Número</th>
                <th class="px-3">Producto</th>
                <th>Descripción</th>
                <th class="px-3">Cantidad</th>
              </tr>
            </thead>
            <tr v-for="i in list.filter((el) => el.income)" :key="i.id">
              <td class="text-center" v-local-date="i.date"></td>
              <td class="text-center">{{ i.serie }}</td>
              <td class="text-center">{{ i.number }}</td>
              <td class="text-center">{{ i.productId }}</td>
              <td>{{ i.productDesc }}</td>
              <td class="text-center" v-decimal="i.quantity"></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="modal-table-container border-0">
        <div class="text-left">
          <span class="modal-title font-weight-bold">Guías de salida</span>
        </div>
        <div>
          <div class="table-responsive">
            <table class="custom-table w-100">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th class="px-3">Serie</th>
                  <th>Número</th>
                  <th class="px-3">Producto</th>
                  <th>Descripción</th>
                  <th class="px-3">Cantidad</th>
                </tr>
              </thead>
              <tr v-for="i in list.filter((el) => !el.income)" :key="i.id">
                <td class="text-center" v-local-date="i.date"></td>
                <td class="text-center">{{ i.serie }}</td>
                <td class="text-center">{{ i.number }}</td>
                <td class="text-center">{{ i.productId }}</td>
                <td>{{ i.productDesc }}</td>
                <td class="text-center" v-decimal="i.quantity"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="conversions-modal-action-buttons d-flex justify-content-end pt-0">
        <span class="button button-light" @click="hide"> Cerrar </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ModalMixin } from "wize-admin";

export default {
  name: "ConversionsModal",
  mixins: [ModalMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="styl">
@import "../Styles/conversions-modal.styl";
</style>
