<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Validar conversiones</h1>
    </div>

    <div class="d-flex flex-column flex-xl-row w-100">
      <div class="table-container w-100">
        <div class="table-header">
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
          >
            <div class="w-full w-md-auto md-flex-1">
              <div
                class="d-flex justify-content-between align-items-center justify-content-lg-start"
              >
                <button
                  type="button"
                  class="filter d-flex align-items-center justify-content-between border-0"
                  @click="showFilters = !showFilters"
                >
                  <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                  <div>Filtrar</div>
                </button>

                <div class="d-md-none">
                  <button
                    type="button"
                    class="button-tooltip text-dark font-weight-bold mr-0"
                    v-b-tooltip.hover
                    title="Recargar"
                    @click="loadData()"
                  >
                    <i class="ri-loop-right-line"></i>
                  </button>

                  <button
                    type="button"
                    class="button-tooltip text-dark font-weight-bold"
                    v-b-tooltip.hover
                    title="Descargar"
                    @click="download()"
                  >
                    <i class="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>

            <section
              class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
            >
              <div class="d-none d-md-flex">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>

              <div class="w-full w-md-auto d-flex flex-column flex-md-row">
                <SearchBar
                  placeholder="Buscar por código o descripción"
                  @search="onSearch"
                  @input="search = $event"
                  class="flex-1"
                />
              </div>
            </section>

            <filter-menu
              :show="showFilters"
              @hide="showFilters = false"
              :options="filterOptions"
              :selectedOptionFilter="filters"
              @setFilters="setFilters"
            ></filter-menu>
          </div>

          <div
            class="w-100 d-flex flex-wrap align-items-center mt-2"
            v-if="showFiltersList"
          >
            <span
              v-for="(val, name) in filters"
              v-bind:key="name"
              class="filter-item"
            >
              {{ getFilterName(name, val) }}
              <button
                type="button"
                class="text-white p-0 bg-transparent border-0 ml-2"
                @click="deleteFilter(name)"
              >
                <i class="ri-close-circle-line"></i>
              </button>
            </span>
          </div>
        </div>

        <div>
          <!-- Mobile -->
          <div class="custom-table-mobile d-xl-none">
            <section class="custom-table-mobile-header">
              <div>
                <span>Código</span>
              </div>
              <div>
                <span>Descripción</span>
              </div>
            </section>

            <div class="custom-table-mobile-body">
              <div
                class="custom-table-mobile-row"
                v-for="o in list"
                :key="o.id"
              >
                <div class="custom-table-mobile-grid">
                  <div>
                    <span>{{ o.productId }}</span>
                  </div>

                  <div>
                    <span>{{ o.productDesc }}</span>
                  </div>

                  <div>
                    <button
                      type="button"
                      class="border-0 bg-transparent"
                      @click="o.showMobileData = !o.showMobileData"
                    >
                      <span v-if="!o.showMobileData">
                        <i class="ri-arrow-down-s-line"></i>
                      </span>
                      <span v-else>
                        <i class="ri-arrow-up-s-line"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div class="custom-table-mobile-options mt-3">
                  <a href="" @click.prevent="showVouchers(o.vouchers)"
                    >Ver guías</a
                  >
                </div>

                <div class="pt-3" v-if="o.showMobileData">
                  <ul class="list-unstyled p-0">
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Sumatoria:</span
                      >
                      <span
                        :class="{ 'text-error': o.quantitySum !== 0 }"
                        v-decimal="o.quantitySum"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <table class="custom-table w-100 d-none d-xl-table">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Sumatoria</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in list" :key="o.productId">
                <td class="text-center">{{ o.productId }}</td>
                <td>{{ o.productDesc }}</td>
                <td
                  :class="{ 'text-error': o.quantitySum !== 0 }"
                  class="text-center"
                  v-decimal="o.quantitySum"
                ></td>
                <td class="text-center d_option pr-md-4">
                  <DropdownTable>
                    <template slot="dropdown-content">
                      <DropdownItem
                        text="Ver guías"
                        @click="showVouchers(o.vouchers)"
                      />
                    </template>
                  </DropdownTable>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="table-footer d-flex justify-content-end align-items-center"
          v-if="!list.length"
        >
          <div class="table-no-results">
            <p class="m-0 px-3">No se encontraron resultados.</p>
          </div>
        </div>
      </div>
    </div>

    <ConversionsModal
      :show="showModal"
      :list="voucherList"
      @hide="showModal = false"
    />
  </div>
</template>

<script>
import {
  Alert,
  Util,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu,
  FilterMixin,
  Constants,
} from "wize-admin";

import moment from "moment"

import fileDownload from "js-file-download";

import { ConversionService, DownloadService } from "@/core/services";
import { required } from "vuelidate/lib/validators";
import ConversionsModal from "../Components/ConversionsModal";

export default {
  mixins: [FilterMixin],
  components: {
    SearchBar,
    ConversionsModal,
    DropdownTable,
    DropdownItem,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      voucherList: [],
      selectedDate: this.$store.getters["app/getAvailableDate"],
      keyword: null,
      tooltipType: null,
      showModal: false,
      showDatePicker: false,
      search: null,
      filterOptions: [
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE,
          isRange: false,
        },
      ],
    };
  },
  validations: {
    selectedDate: { required },
  },
  methods: {
    async loadData() {
      this.$v.$touch();
      if (this.$v.$error) return;
      try {
        await this.$store.dispatch("app/loading", true);

        // Asignar filtros
        let filters = {
          ...this.filters,
        };

        if (filters.date) {
          this.selectedDate = moment(filters.date.startDate).format(
            "YYYY-MM-DD"
          );
        }

        const params = { date: this.selectedDate, search: this.search };
        if (this.keyword) params.keyword = this.keyword;

        const response = await ConversionService.listDailyConversionSums(
          params
        );

        this.list = response.payload.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async download() {
      this.$v.$touch();
      if (this.$v.$error || this.list.length === 0) return;

      try {
        await this.$store.dispatch("app/loading", true);
        const params = { date: this.selectedDate, search: this.search };
        const response = await DownloadService.dailyConversionSum(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onSearch(keyword) {
      this.search = keyword;
      this.loadData();
    },
    showVouchers(list) {
      this.voucherList = list;
      this.showModal = true;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
