<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar relación" : "Nueva relación" }}
      </h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>
            {{
              editing ? "Datos de la relación" : "Datos de la nueva relación"
            }}
          </h3>
        </b-card-header>

        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save">
            <div class="form-section">
              <div class="form-group">
                <label for="code">Código *</label>

                <FormInput
                  id="code"
                  type="text"
                  maxlength="15"
                  placeholder="Ingresar código"
                  :show-error="
                    $v.relationship.productId.$error &&
                    !$v.relationship.productId.required
                  "
                  v-model="relationship.productId"
                />

                <form-error
                  message="Código es requerido"
                  v-if="
                    $v.relationship.productId.$error &&
                    !$v.relationship.productId.required
                  "
                ></form-error>
              </div>

              <div class="form-group">
                <label for="description">Descripción *</label>

                <FormInput
                  id="description"
                  type="text"
                  maxlength="200"
                  placeholder="Ingresar descripción"
                  :show-error="
                    $v.relationship.productDesc.$error &&
                    !$v.relationship.productDesc.required
                  "
                  v-model="relationship.productDesc"
                />

                <form-error
                  message="Descripción es requerida"
                  v-if="
                    $v.relationship.productDesc.$error &&
                    !$v.relationship.productDesc.required
                  "
                ></form-error>
              </div>

              <div class="form-group">
                <label for="code">Código equivalente *</label>

                <FormInput
                  id="relatedCode"
                  type="text"
                  maxlength="15"
                  placeholder="Ingresar código equivalente"
                  :show-error="
                    $v.relationship.productRelatedId.$error &&
                    !$v.relationship.productRelatedId.required
                  "
                  v-model="relationship.productRelatedId"
                />

                <form-error
                  message="Código equivalente es requerido"
                  v-if="
                    $v.relationship.productRelatedId.$error &&
                    !$v.relationship.productRelatedId.required
                  "
                ></form-error>
              </div>

              <div class="form-group mb-0">
                <label for="conversionFactor">Unidad de conversión *</label>

                <FormInput
                  id="conversionFactor"
                  type="text"
                  maxlength="10"
                  placeholder="Ingresar unidad de conversión"
                  :show-error="
                    ($v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.required) ||
                    ($v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.decimal) ||
                    ($v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.greaterThan)
                  "
                  v-model="relationship.conversionFactor"
                />

                <form-error
                  message="Unidad de conversión es requerida"
                  v-if="
                    $v.relationship.conversionFactor.$error &&
                    !$v.relationship.conversionFactor.required
                  "
                ></form-error>

                <form-error
                  message="La unidad de conversión debe ser un valor numérico"
                  v-if="
                    $v.relationship.conversionFactor.$error &&
                    !$v.relationship.conversionFactor.decimal
                  "
                ></form-error>

                <form-error
                  message="La unidad de conversión debe ser un valor mayor a 0"
                  v-if="
                    $v.relationship.conversionFactor.$error &&
                    !$v.relationship.conversionFactor.greaterThan
                  "
                ></form-error>
              </div>

              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'config-relation-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, CustomValidators, FormError, FormInput } from "wize-admin";

import { ProductService } from "@/core/services";
import { decimal, required } from "vuelidate/lib/validators";

export default {
  components: {
    FormError,
    FormInput,
  },
  data() {
    return {
      relationship: {
        productId: null,
        productDesc: null,
        productRelatedId: null,
        conversionFactor: null,
      },
      editing: false,
    };
  },
  validations: {
    relationship: {
      productId: { required },
      productDesc: { required },
      productRelatedId: { required },
      conversionFactor: {
        required,
        decimal,
        greaterThan: CustomValidators.greaterThan(0),
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      const data = { ...this.relationship };

      try {
        let response;
        if (!this.editing)
          response = await ProductService.saveRelationship(data);
        else
          response = await ProductService.updateRelationship(
            this.$route.params.id,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-relation-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProductService.getRelationship(id);
        this.relationship = { ...response.payload };
        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
