var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar relación" : "Nueva relación")+" ")])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos de la relación" : "Datos de la nueva relación")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Código *")]),_c('FormInput',{attrs:{"id":"code","type":"text","maxlength":"15","placeholder":"Ingresar código","show-error":_vm.$v.relationship.productId.$error &&
                  !_vm.$v.relationship.productId.required},model:{value:(_vm.relationship.productId),callback:function ($$v) {_vm.$set(_vm.relationship, "productId", $$v)},expression:"relationship.productId"}}),(
                  _vm.$v.relationship.productId.$error &&
                  !_vm.$v.relationship.productId.required
                )?_c('form-error',{attrs:{"message":"Código es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('FormInput',{attrs:{"id":"description","type":"text","maxlength":"200","placeholder":"Ingresar descripción","show-error":_vm.$v.relationship.productDesc.$error &&
                  !_vm.$v.relationship.productDesc.required},model:{value:(_vm.relationship.productDesc),callback:function ($$v) {_vm.$set(_vm.relationship, "productDesc", $$v)},expression:"relationship.productDesc"}}),(
                  _vm.$v.relationship.productDesc.$error &&
                  !_vm.$v.relationship.productDesc.required
                )?_c('form-error',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Código equivalente *")]),_c('FormInput',{attrs:{"id":"relatedCode","type":"text","maxlength":"15","placeholder":"Ingresar código equivalente","show-error":_vm.$v.relationship.productRelatedId.$error &&
                  !_vm.$v.relationship.productRelatedId.required},model:{value:(_vm.relationship.productRelatedId),callback:function ($$v) {_vm.$set(_vm.relationship, "productRelatedId", $$v)},expression:"relationship.productRelatedId"}}),(
                  _vm.$v.relationship.productRelatedId.$error &&
                  !_vm.$v.relationship.productRelatedId.required
                )?_c('form-error',{attrs:{"message":"Código equivalente es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"conversionFactor"}},[_vm._v("Unidad de conversión *")]),_c('FormInput',{attrs:{"id":"conversionFactor","type":"text","maxlength":"10","placeholder":"Ingresar unidad de conversión","show-error":(_vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.required) ||
                  (_vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.decimal) ||
                  (_vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.greaterThan)},model:{value:(_vm.relationship.conversionFactor),callback:function ($$v) {_vm.$set(_vm.relationship, "conversionFactor", $$v)},expression:"relationship.conversionFactor"}}),(
                  _vm.$v.relationship.conversionFactor.$error &&
                  !_vm.$v.relationship.conversionFactor.required
                )?_c('form-error',{attrs:{"message":"Unidad de conversión es requerida"}}):_vm._e(),(
                  _vm.$v.relationship.conversionFactor.$error &&
                  !_vm.$v.relationship.conversionFactor.decimal
                )?_c('form-error',{attrs:{"message":"La unidad de conversión debe ser un valor numérico"}}):_vm._e(),(
                  _vm.$v.relationship.conversionFactor.$error &&
                  !_vm.$v.relationship.conversionFactor.greaterThan
                )?_c('form-error',{attrs:{"message":"La unidad de conversión debe ser un valor mayor a 0"}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-relation-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }