const Errors = {
  SERVER_UNAVAILABLE: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const VoucherSerieType = {
  INCOME: {key: 1, label: 'INGRESO'},
  OUTCOME: {key: 2, label: 'SALIDA'}
}

const VoucherSerieStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green', action: 'Suspender'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow', action: 'Activar'}
}

const DailyProcessStatus = {
  RUNNING: {key: 1, label: 'EN PROCESO', color: 'badge-yellow'},
  FINISHED: {key: 2, label: 'PROCESADO', color: 'badge-green'},
  CLOSED: {key: 3, label: 'CERRADO', color: 'badge-gray'},
  INACTIVE: {key: 4, label: 'ELIMINADO', color: 'badge-red'}
}

const DataDeletionType = {
  PROCESSES: {key: 1, label: 'PROCESOS DIARIOS'},
  VOUCHERS: {key: 2, label: 'CARGAS DIARIAS'},
  FINALS: {key: 3, label: 'CARGAS FINALES'},
  ALL: {key: 4, label: 'TODA LA INFORMACIÓN'}
}

const UpdateCostType = {
  COST_G15: {key: 'COST_G15', label: 'Actualización de guías G15'},
  COST_G65: {key: 'COST_G65', label: 'Actualización de guías G65'}
}

export default {
  Errors,
  VoucherSerieType,
  VoucherSerieStatus,
  DailyProcessStatus,
  DataDeletionType,
  UpdateCostType
}
