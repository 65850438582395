<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Relación entre productos</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-end align-items-center justify-content-lg-start"
            >
              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por código, descripción o código equivalente"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="d-flex justify-content-end mt-2 mt-md-0">
                <!-- <b-button
                  :to="{ name: 'config-serie-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                > -->

                <b-button
                  :to="{ name: 'config-relation-load' }"
                  variant="none"
                  class="button button-primary mx-2"
                  >Cargar</b-button
                >
                <b-button
                  :to="{ name: 'config-relation-create' }"
                  variant="none"
                  class="button button-primary"
                  >Agregar relación</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Código</span>
            </div>
            <div>
              <span>Descripción</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <p class="text-overflow-ellipsis">{{ o.productId }}</p>
                </div>

                <div>
                  <span>{{ o.productDesc }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{ name: 'config-relation-edit', params: { id: o.id } }"
                  class="mr-3"
                >
                  Editar
                </router-link>

                <a href="" @click.prevent="openDelete(o)">Eliminar</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Código:</span
                    >
                    <span>{{ o.productId }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Tipo de movimiento:</span
                    >
                    <span>Free</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Código equivalente</th>
              <th>Unidad de conversión</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.productId }}</td>
              <td class="pl-5">{{ o.productDesc }}</td>
              <td class="text-center">{{ o.productRelatedId }}</td>
              <td class="text-center">{{ o.conversionFactor }}</td>

              <td class="text-center d_option pr-md-4">
                <custom-tooltip
                  text="Ver más opciones"
                  v-show="tooltipType === o.id"
                  custom-class="relation-ship"
                />
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{ name: 'config-relation-edit', params: { id: o.id } }"
                    />
                    <DropdownItem
                      text="Eliminar"
                      @click="openDelete(o)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="deleteRelationship"
    />
  </div>
</template>

<script>
import {
  Alert,
  Util,
  PaginationMixin,
  ConfirmationModalMixin,
} from "wize-admin";

import { DownloadService, ProductService } from "@/core/services";
import fileDownload from "js-file-download";

import {
  DropdownTable,
  DropdownItem,
  SearchBar,
  CustomTooltip,
  ConfirmationModal,
} from "wize-admin";

export default {
  components: {
    SearchBar,
    CustomTooltip,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
    };
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "productId",
          search: this.search,
        };
        const response = await ProductService.listRelationships(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async deleteRelationship() {
      this.closeConfirmationModal();
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await ProductService.deleteRelationship(this.selectedOption.id)
        ).payload;

        const rel = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (rel) {
          const index = this.list.indexOf(rel);
          this.list.splice(index, 1);
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async download() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.productRelationship();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar" });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus">
@import '../Styles/series-list.styl'
</style>
