<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        Listar diferencia de costos entre guías G15 y G65
      </h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha</span>
            </div>
            <div>
              <span>Suma G15</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.date"></span>
                </div>

                <div>
                  <span v-decimal:pen="o.g15value"></span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Suma G65:</span
                    >
                    <span v-decimal:pen="o.g65value"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Diferencia:</span
                    >
                    <span v-decimal:pen="o.difference"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Suma G15</th>
              <th>Suma G65</th>
              <th>Diferencia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
              <td class="text-center" v-decimal:pen="o.g15value"></td>
              <td class="text-center" v-decimal:pen="o.g65value"></td>
              <td class="text-center" v-decimal:pen="o.difference"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center" v-if="!list.length">
        <div class="table-no-results">
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Alert, Constants, FilterMenu, FilterMixin } from "wize-admin";
import { VoucherService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [FilterMixin],
  data() {
    return {
      list: [],
      startDate: this.$store.getters["app/getAvailableDate"],
      endDate: this.$store.getters["app/getAvailableDate"],
      isOpenStartPicker: false,
      isOpenEndPicker: false,
      filterOptions: [
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE,
          isRange: true,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FilterMenu,
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    async loadData() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      try {
        await this.$store.dispatch("app/loading", true);

        // Asignar filtros
        let filters = {
          ...this.filters,
        };

        if (filters.date) {
          this.startDate = moment(filters.date.startDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = moment(filters.date.endDate).format(
            "YYYY-MM-DD"
          );
        } else {
          this.startDate = this.$store.getters["app/getAvailableDate"],
          this.endDate = this.$store.getters["app/getAvailableDate"]
        }

        const params = { startDate: this.startDate, endDate: this.endDate };
        if (this.keyword) params.keyword = this.keyword;
        const response = await VoucherService.lisDailyVoucherCostDifference(
          params
        );

        this.list = response.payload.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
