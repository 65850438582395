<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Series</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar series</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por código o descripción"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'config-serie-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Código</span>
            </div>
            <div>
              <span>Descripción</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.code }}</span>
                </div>

                <div>
                  <span>{{ o.description }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{ name: 'config-serie-edit', params: { serieId: o.id } }"
                >
                  Editar
                </router-link>

                <a href="" @click.prevent="openChangeStatus(o)" class="mx-3">{{
                  getStatus(o.status).action
                }}</a>

                <a href="" @click.prevent="openDelete(o)">Eliminar</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Tipo de movimiento:</span
                    >
                    <span>{{ getType(o.type).label }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Tipo de movimiento</th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.code }}</td>
              <td class="pl-5">{{ o.description }}</td>
              <td class="text-center">{{ getType(o.type).label }}</td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'config-serie-edit',
                        params: { serieId: o.id },
                      }"
                    />
                    <DropdownItem
                      :text="getStatus(o.status).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
  FilterMenu,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { VoucherSerieService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Tipo de movimiento",
          key: "type",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.VoucherSerieType.INCOME,
            LocalConstants.VoucherSerieType.OUTCOME,
          ],
        },
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.VoucherSerieStatus.ACTIVE,
            LocalConstants.VoucherSerieStatus.INACTIVE,
          ],
        },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "code,asc",
          search: this.search,
          ...this.filters,
        };
        const response = await VoucherSerieService.list(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getType(key) {
      return Util.searchValue(key, LocalConstants.VoucherSerieType);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.VoucherSerieStatus);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteSerie();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await VoucherSerieService.toggleStatus(this.selectedOption.id)
        ).payload;

        const serie = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (serie) {
          const index = this.list.indexOf(serie);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado de la serie?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteSerie() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await VoucherSerieService.delete(this.selectedOption.id))
          .payload;

        const serie = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (serie) {
          const index = this.list.indexOf(serie);
          this.list.splice(index, 1);
          this.total--;
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus">
@import '../Styles/series-list.styl'
</style>
