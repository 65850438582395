<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Guías externas</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-end align-items-center justify-content-lg-start"
            >
              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold mr-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por producto, descripción, serie, número, creado por"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'daily-custom-voucher-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Crear</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha</span>
            </div>
            <div>
              <span>Serie</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.date"></span>
                </div>

                <div>
                  <span>{{ o.serie }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options d-flex mt-3">
                <router-link
                  :to="{
                    name: 'daily-custom-voucher-edit',
                    params: { voucherId: o.id },
                  }"
                  class="mr-3"
                >
                  Editar
                </router-link>
                <a href="" class="d-block w-50" @click.prevent="openDelete(o)"
                  >Eliminar</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Número:</span
                    >
                    <span>{{ o.number || "-" }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Producto:</span
                    >
                    <span>{{ o.productId || "-" }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Descripción:</span
                    >
                    <span>
                      {{ o.productDesc || "-" }}
                    </span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cantidad:</span
                    >
                    <span v-decimal="o.quantity"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Unidad:</span
                    >
                    <span>{{ o.unit || "-" }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Costo:</span
                    >
                    <span v-decimal:pen="o.cost"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Creado por:</span
                    >
                    <span>{{ o.createdBy || "-" }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Actualizado por:</span
                    >
                    <span>{{ o.updatedBy || "-" }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Serie</th>
              <th>Número</th>
              <th>Producto</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Unidad</th>
              <th>Costo</th>
              <th>Creado por</th>
              <th>Actualizado por</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center" v-local-date="o.date"></td>
              <td class="text-center">{{ o.serie }}</td>
              <td class="text-center">{{ o.number }}</td>
              <td class="text-center">{{ o.productId }}</td>
              <td>{{ o.productDesc }}</td>
              <td class="text-center" v-decimal="o.quantity"></td>
              <td class="text-center">{{ o.unit }}</td>
              <td class="text-center" v-decimal:pen="o.cost"></td>
              <td class="text-center">{{ o.createdBy }}</td>
              <td class="text-center">{{ o.updatedBy }}</td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'daily-custom-voucher-edit',
                        params: { voucherId: o.id },
                      }"
                    />

                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="deleteVoucher"
    />
  </div>
</template>

<script>
import {
  Alert,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
} from "wize-admin";

import { mapGetters } from "vuex";
import fileDownload from "js-file-download";

import { DownloadService, VoucherService } from "@/core/services";

export default {
  components: {
    SearchBar,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
    };
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  computed: {
    ...mapGetters({
      lastClosedDate: "app/getLastClosedDate",
    }),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "date,desc",
          search: this.search,
          custom: true
        };
        const response = await VoucherService.listDaily(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },

    async deleteVoucher() {
      try {
        this.closeConfirmationModal();
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await VoucherService.deleteCustomDaily(this.selectedOption.id)
        ).payload;

        const batch = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (batch) {
          const index = this.list.indexOf(batch);
          this.list.splice(index, 1);
        }
        Alert.success("Guía externa eliminada correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    canModify() {
      return true; // moment(this.lastClosedDate).diff(batchDate) < 0; TODO undo
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar" });
    },
    async download() {
      if (this.list.length === 0) return;
      try {
        await this.$store.dispatch("app/loading", true);
        const params = { search: this.search };
        const response = await DownloadService.dailyCustomVouchers(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
