<template>
  <dropdown-basic
    class="businesses-dropdown"
    :text="business.name"
    :left="true"
    v-if="business"
  >
    <template #dropdown-content>
      <!-- Título -->
      <b-dropdown-item
        class="dropdown-title"
        @click="$router.push({ name: 'businesses' })"
        >Ver todas las empresas</b-dropdown-item
      >

      <b-dropdown-item
        v-for="i in businesses"
        :key="i.id"
        @click="changeBusiness(i)"
        v-show="i.id !== business.id"
        >{{ i.name }}
      </b-dropdown-item>
    </template>
  </dropdown-basic>
</template>

<script>
import { mapGetters } from "vuex";

import { DropdownBasic } from "wize-admin";

export default {
  components: {
    DropdownBasic,
  },
  methods: {
    async changeBusiness(business) {
      this.$store.commit("app/business", business);

      this.$emit('change')

      await this.$store.dispatch("app/loadAvailableDate");
      await this.$store.dispatch("app/loadStartDate");
      await this.$store.dispatch("app/loadLastClosedDate");

      if (this.$route.name !== "dashboard") {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
  computed: {
    ...mapGetters({
      business: "app/getBusiness",
      businesses: "app/getBusinesses",
    }),
  },
};
</script>

<style lang="stylus">
@import "../../styles/components/layout/businesses-dropdown.styl"
</style>
