<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Descargar libro</h1>
    </div>
    <div>
      <b-card tag="article" no-body class="custom-card custom-card--sm">
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Seleccione fecha</h3>
        </b-card-header>

        <b-card-body class="custom-card-body">
          <form class="custom-form" @submit.prevent="save">
            <div class="mb-4">
              <label for="start-date">Fecha inicio</label>
              <FormDatepicker
                label-id="start-date"
                :show-calendar="isOpenStartPicker"
                :min="$store.getters['app/getStartDate']"
                :max="$store.getters['app/getAvailableDate']"
                :hideHeader="true"
                @show="isOpenStartPicker = true"
                @hidden="isOpenStartPicker = false"
                v-model="startDate"
                class="w-100"
              />
              <form-error
                message="Fecha de inicio es requerida"
                v-if="$v.startDate.$error && !$v.startDate.required"
              ></form-error>
            </div>
            <div class="mb-0">
              <label for="end-date">Fecha final</label>
              <b-input-group>
                <FormDatepicker
                  label-id="end-date"
                  :show-calendar="isOpenEndPicker"
                  :min="$store.getters['app/getStartDate']"
                  :max="$store.getters['app/getAvailableDate']"
                  :hideHeader="true"
                  @show="isOpenEndPicker = true"
                  @hidden="isOpenEndPicker = false"
                  v-model="endDate"
                  class="w-100"
                />
              </b-input-group>

              <form-error
                message="Fecha final es requerida"
                v-if="$v.endDate.$error && !$v.endDate.required"
              ></form-error>
            </div>
            <div class="form-group-buttons text-right w-100">
              <button class="button button-primary" type="submit">
                Descargar
              </button>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, Util, FormError, FormDatepicker } from "wize-admin";
import fileDownload from "js-file-download";

import { DownloadService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      startDate: this.$store.getters["app/getAvailableDate"],
      endDate: this.$store.getters["app/getAvailableDate"],
      isOpenStartPicker: false,
      isOpenEndPicker: false,
    };
  },
  components: {
    FormError,
    FormDatepicker,
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      try {
        const response = await DownloadService.dailyInventoryFiles({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>
