function capitalizeName(name) {
  if(!name) return '';
  return name.toLowerCase().replace(/\b\w/g, function(char) {
    return char.toUpperCase();
  });
}

export default {
  capitalizeName
}
