<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar guía externa" : "Nueva guía externa" }}
      </h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>
            {{
              editing
                ? "Datos de la guía externa"
                : "Datos de la nueva guía externa"
            }}
          </h3>
        </b-card-header>
        <b-card-body class="custom-card-body">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-group">
              <label for="type">Tipo de comprobante *</label>
              <FormSelect
                id="type"
                v-model="voucher.voucherTypeId"
                :items="voucherTypes"
                defaultOption="Elegir tipo de comprobante"
                :showError="$v.voucher.voucherTypeId.$error"
              />
              <form-error
                message="Tipo de comprobante es requerido"
                v-if="
                  $v.voucher.voucherTypeId.$error &&
                  !$v.voucher.voucherTypeId.required
                "
              ></form-error>
            </div>
            <div class="form-group">
              <label for="serie">Serie *</label>
              <FormSelect
                id="serie"
                v-model="voucher.serie"
                :items="voucherSeries"
                defaultOption="Elegir serie"
                :showError="$v.voucher.serie.$error"
              />
              <form-error
                message="Serie es requerida"
                v-if="$v.voucher.serie.$error && !$v.voucher.serie.required"
              ></form-error>
            </div>
            <div class="form-group d-flex flex-column">
              <label for="input-date">Fecha *</label>

              <FormDatepicker
                label-id="input-date"
                :show-calendar="showDatePicker"
                :hideHeader="false"
                :error="$v.voucher.date.$error"
                @show="showDatePicker = true"
                @hidden="showDatePicker = false"
                v-model="voucher.date"
                class="w-100 datepicker-container--form-input"
              />

              <form-error
                message="Fecha es requerida"
                v-if="$v.voucher.date.$error && !$v.voucher.date.required"
              ></form-error>
            </div>
            <div class="form-group">
              <label for="number">Número de comprobante *</label>

              <FormInput
                id="number"
                type="text"
                maxlength="200"
                placeholder="Ingresar número de comprobante"
                :show-error="
                  ($v.voucher.number.$error && !$v.voucher.number.required) ||
                  ($v.voucher.number.$error && !$v.voucher.number.numeric)
                "
                v-model="voucher.number"
              />

              <form-error
                message="Número de comprobante es requerido"
                v-if="$v.voucher.number.$error && !$v.voucher.number.required"
              ></form-error>
              <form-error
                message="El número de comprobante debe ser un valor numérico"
                v-if="$v.voucher.number.$error && !$v.voucher.number.numeric"
              ></form-error>
            </div>
            <div class="form-group">
              <label for="document">Documento (GC, GM) *</label>

              <FormInput
                id="document"
                type="text"
                maxlength="200"
                placeholder="Ingresar documento"
                :show-error="
                  $v.voucher.document.$error && !$v.voucher.document.required
                "
                v-model="voucher.document"
              />

              <form-error
                message="Documento es requerido"
                v-if="
                  $v.voucher.document.$error && !$v.voucher.document.required
                "
              ></form-error>
            </div>
            <div class="form-group">
              <label for="productId">Código de producto *</label>

              <FormInput
                id="productId"
                type="text"
                maxlength="20"
                placeholder="Ingresar código de producto"
                :show-error="
                  $v.voucher.productId.$error && !$v.voucher.productId.required
                "
                v-model="voucher.productId"
              />

              <form-error
                message="Código de producto es requerido"
                v-if="
                  $v.voucher.productId.$error && !$v.voucher.productId.required
                "
              ></form-error>
            </div>
            <div class="form-group">
              <label for="description">Descripción *</label>

              <FormInput
                id="description"
                type="text"
                maxlength="200"
                placeholder="Ingresar descripción"
                :show-error="
                  $v.voucher.productDesc.$error &&
                  !$v.voucher.productDesc.required
                "
                v-model="voucher.productDesc"
              />

              <form-error
                message="Descripción es requerida"
                v-if="
                  $v.voucher.productDesc.$error &&
                  !$v.voucher.productDesc.required
                "
              ></form-error>
            </div>
            <div class="form-group">
              <label for="description">Unidad (UND, KG) *</label>

              <FormInput
                id="unit"
                type="text"
                maxlength="10"
                placeholder="Ingresar unidad"
                :show-error="
                  $v.voucher.unit.$error && !$v.voucher.unit.required
                "
                v-model="voucher.unit"
              />

              <form-error
                message="Unidad es requerida"
                v-if="$v.voucher.unit.$error && !$v.voucher.unit.required"
              ></form-error>
            </div>
            <div class="form-group">
              <label for="quantity">Cantidad *</label>

              <FormInput
                id="quantity"
                type="text"
                maxlength="20"
                placeholder="Ingresar cantidad"
                :show-error="
                  ($v.voucher.quantity.$error &&
                    !$v.voucher.quantity.required) ||
                  ($v.voucher.quantity.$error &&
                    !$v.voucher.quantity.decimal) ||
                  ($v.$error && !$v.voucher.quantity.greaterThan)
                "
                v-model="voucher.quantity"
              />

              <form-error
                message="Cantidad es requerida"
                v-if="
                  $v.voucher.quantity.$error && !$v.voucher.quantity.required
                "
              ></form-error>

              <form-error
                message="La cantidad debe ser un valor numérico"
                v-if="
                  $v.voucher.quantity.$error && !$v.voucher.quantity.decimal
                "
              ></form-error>

              <form-error
                message="La cantidad debe ser un valor mayor a 0"
                v-if="$v.$error && !$v.voucher.quantity.greaterThan"
              ></form-error>
            </div>
            <div class="form-group">
              <label for="cost">Costo *</label>

              <FormInput
                id="cost"
                type="text"
                maxlength="20"
                placeholder="Ingresar costo"
                :show-error="
                  ($v.voucher.cost.$error && !$v.voucher.cost.required) ||
                  ($v.voucher.cost.$error && !$v.voucher.cost.decimal) ||
                  ($v.voucher.cost.$error && !$v.voucher.cost.minValue)
                "
                v-model="voucher.cost"
              />

              <form-error
                message="Costo es requerido"
                v-if="$v.voucher.cost.$error && !$v.voucher.cost.required"
              ></form-error>

              <form-error
                message="El costo debe ser un valor numérico"
                v-if="$v.voucher.cost.$error && !$v.voucher.cost.decimal"
              ></form-error>

              <form-error
                message="El costo no puede ser un valor menor a 0"
                v-if="$v.voucher.cost.$error && !$v.voucher.cost.minValue"
              ></form-error>
            </div>
            <div class="form-group-buttons text-right">
              <router-link
                :to="{ name: 'daily-custom-voucher-list' }"
                class="button button-light"
              >
                {{ editing ? "Volver" : "Cancelar" }}
              </router-link>
              <button class="button button-primary" type="submit">
                Guardar
              </button>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  CustomValidators,
  FormError,
  FormSelect,
  FormDatepicker,
  FormInput,
} from "wize-admin";

import {
  VoucherSerieService,
  VoucherService,
  VoucherTypeService,
} from "@/core/services";
import { decimal, minValue, numeric, required } from "vuelidate/lib/validators";

export default {
  components: { FormError, FormSelect, FormDatepicker, FormInput },
  data() {
    return {
      voucher: {
        date: null,
        voucherTypeId: null,
        serie: null,
        document: null,
        number: null,
        productId: null,
        productDesc: null,
        unit: null,
        quantity: null,
        cost: null,
      },
      voucherTypes: [],
      voucherSeries: [],
      editing: false,
      loaded: false,
      showDatePicker: false,
    };
  },
  validations: {
    voucher: {
      date: { required },
      voucherTypeId: { required },
      serie: { required },
      document: { required },
      number: { required, numeric },
      productId: { required },
      productDesc: { required },
      unit: { required },
      quantity: {
        required,
        decimal,
        greaterThan: CustomValidators.greaterThan(0),
      },
      cost: { required, decimal, minValue: minValue(0) },
    },
  },
  watch: {
    "$route.params.voucherId": function () {
      if (this.$route.params.voucherId) this.load(this.$route.params.voucherId);
    },
    "voucher.voucherTypeId": async function (newVal) {
      if (this.loaded) await this.getVoucherSeries(newVal);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      const data = { ...this.voucher };

      try {
        let response;
        if (!this.editing)
          response = await VoucherService.saveCustomDaily(data);
        else
          response = await VoucherService.updateCustomDaily(
            this.$route.params.voucherId,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "daily-custom-voucher-edit",
            params: { voucherId: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      }
    },
    async load(voucherId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await VoucherService.getCustomDaily(voucherId);
        const {
          date,
          voucherType,
          serie,
          document,
          number,
          productId,
          productDesc,
          unit,
          quantity,
          cost,
        } = response.payload;
        this.voucher.date = date;
        this.voucher.serie = serie;
        this.voucher.document = document;
        this.voucher.number = number;
        this.voucher.productId = productId;
        this.voucher.productDesc = productDesc;
        this.voucher.unit = unit;
        this.voucher.quantity = quantity;
        this.voucher.cost = cost;
        this.voucher.voucherTypeId = voucherType.id;
        await this.getVoucherSeries(this.voucher.voucherTypeId);
        this.voucher.serie = response.payload.serie;
        this.loaded = true;
        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async getVoucherSeries(voucherTypeId) {
      const self = this;
      return new Promise((resolve) => {
        VoucherSerieService.getActive({ voucherTypeId: voucherTypeId }).then(
          async (resp) => {
            self.voucherSeries = [];
            await resp.payload.map((it) => {
              self.voucherSeries.push({
                key: it.code,
                label: `${it.code} - ${it.description}`,
              });
            });
            if (self.loaded) self.voucher.serie = null;
            resolve();
          }
        );
      });
    },
  },
  async created() {
    try {
      this.$store.dispatch("app/loading", true);
      const resp = await VoucherTypeService.listActiveHasExternalVouchers();
      this.voucherTypes = [];
      resp.payload.forEach((it) => {
        this.voucherTypes.push({ key: it.id, label: it.name });
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.$store.dispatch("app/loading", false);
    }

    if (this.$route.params.voucherId) this.load(this.$route.params.voucherId);
    else {
      this.loaded = true;
    }
  },
};
</script>

<style lang="stylus" scoped></style>
