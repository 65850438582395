<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Guías internas</h1>
    </div>
    <div class="d-flex w-100">
      <div class="table-container w-100">
        <div class="table-header">
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
          >
            <div class="w-full w-md-auto md-flex-1">
              <div
                class="d-flex justify-content-between align-items-center justify-content-lg-start"
              >
                <button
                  type="button"
                  class="filter d-flex align-items-center justify-content-between border-0"
                  @click="showFilters = !showFilters"
                >
                  <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                  <div>Filtrar guías internas</div>
                </button>

                <div class="d-md-none">
                  <button
                    type="button"
                    class="button-tooltip text-dark font-weight-bold m-0"
                    v-b-tooltip.hover
                    title="Recargar"
                    @click="loadData()"
                  >
                    <i class="ri-loop-right-line"></i>
                  </button>
                  <button
                    type="button"
                    class="button-tooltip text-dark font-weight-bold mr-0"
                    v-b-tooltip.hover
                    title="Descargar"
                    @click="download()"
                  >
                    <i class="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>

            <section
              class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
            >
              <div class="d-none d-md-flex">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>

              <div class="w-full w-md-auto d-flex flex-column flex-md-row">
                <SearchBar
                  placeholder="Buscar por producto, descripción, serie, número, creado por"
                  @search="onSearch"
                  @input="search = $event"
                  class="flex-1"
                />

                <div class="text-right mt-2 mt-md-0">
                  <b-button
                    :to="{ name: 'config-serie-create' }"
                    variant="none"
                    class="button button-primary ml-md-2"
                    >Agregar</b-button
                  >
                </div>
              </div>
            </section>

            <filter-menu
              :show="showFilters"
              @hide="showFilters = false"
              :options="filterOptions"
              :selectedOptionFilter="filters"
              @setFilters="setFilters"
            ></filter-menu>
          </div>

          <div
            class="w-100 d-flex flex-wrap align-items-center mt-2"
            v-if="showFiltersList"
          >
            <span
              v-for="(val, name) in filters"
              v-bind:key="name"
              class="filter-item"
            >
              {{ getFilterName(name, val) }}
              <button
                type="button"
                class="text-white p-0 bg-transparent border-0 ml-2"
                @click="deleteFilter(name)"
              >
                <i class="ri-close-circle-line"></i>
              </button>
            </span>
          </div>
        </div>

        <div>
          <!-- Mobile -->
          <div class="custom-table-mobile d-xl-none">
            <section class="custom-table-mobile-header">
              <div>
                <span>Fecha</span>
              </div>
              <div>
                <span>Serie</span>
              </div>
            </section>

            <div class="custom-table-mobile-body">
              <div
                class="custom-table-mobile-row"
                v-for="o in list"
                :key="o.id"
              >
                <div class="custom-table-mobile-grid">
                  <div>
                    <span v-local-date="o.date"></span>
                  </div>

                  <div>
                    <span>{{ o.serie }}</span>
                  </div>

                  <div>
                    <button
                      type="button"
                      class="border-0 bg-transparent"
                      @click="o.showMobileData = !o.showMobileData"
                    >
                      <span v-if="!o.showMobileData">
                        <i class="ri-arrow-down-s-line"></i>
                      </span>
                      <span v-else>
                        <i class="ri-arrow-up-s-line"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div class="pt-3" v-if="o.showMobileData">
                  <ul class="list-unstyled p-0">
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Número:</span
                      >
                      <span>{{ o.number || "-" }}</span>
                    </li>

                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Producto:</span
                      >
                      <span>{{ o.productId || "-" }}</span>
                    </li>
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Descripción:</span
                      >
                      <span>
                        {{ o.productDesc || "-" }}
                      </span>
                    </li>
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Cantidad:</span
                      >
                      <span v-decimal="o.quantity"></span>
                    </li>
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Unidad:</span
                      >
                      <span>{{ o.unit || "-" }}</span>
                    </li>
                    <li>
                      <span class="font-weight-medium d-inline-block mr-2"
                        >Costo:</span
                      >
                      <span v-decimal:pen="o.cost"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Desktop -->
          <table class="custom-table w-100 d-none d-xl-table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Serie</th>
                <th>Número</th>
                <th>Producto</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Costo</th>
                <th>Actualizado por</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in list" :key="o.id">
                <td class="text-center" v-local-date="o.date"></td>
                <td class="text-center">{{ o.serie }}</td>
                <td class="text-center">{{ o.number }}</td>
                <td class="text-center">{{ o.productId }}</td>
                <td>{{ o.productDesc }}</td>
                <td class="text-center" v-decimal="o.quantity"></td>
                <td class="text-center">{{ o.unit }}</td>
                <td class="text-center" v-decimal:pen="o.cost"></td>
                <td class="text-center">{{ o.updatedBy }}</td>
                <td class="text-center d_option pr-md-4">
                  <DropdownTable>
                    <template slot="DropdownTable-content">
                      <DropdownItem
                        text="Editar costo"
                        @click="
                          $router.push({
                            name: 'daily-voucher-edit-cost',
                            params: { voucherId: o.id },
                          })
                        "
                      />
                    </template>
                  </DropdownTable>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer d-flex justify-content-end align-items-center">
          <pagination
            :total="total"
            :page="page"
            :page-size="pageSize"
            :pages="pages"
            :changePage="changePage"
            :changePageSize="changePageSize"
            v-if="list.length"
          />

          <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DropdownTable,
  DropdownItem,
  FilterMenu,
  SearchBar,
  ConfirmationModalMixin,
  FilterMixin,
  PaginationMixin,
  Alert,
  Constants,
} from "wize-admin";
import { VoucherService } from "@/core/services";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      selectedDate: null,
      showDatePicker: false,
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Costo cero",
          key: "costZero",
          type: Constants.TypeFilters.SINGLE,
          options: [
            { key: 1, label: "SÍ" },
            { key: 0, label: "NO" },
          ],
        },
        {
          label: "Series",
          key: "series",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            { key: "G15", label: "G15" },
            { key: "G65", label: "G65" },
          ],
        },
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE,
          isRange: false,
        },
      ],
    };
  },
  validations: {
    selectedDate: {},
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  computed: {
    ...mapGetters({
      lastClosedDate: "app/getLastClosedDate",
    }),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        // Asignar filtros
        let filters = {
          ...this.filters,
        };

        if (filters.date) {
          this.selectedDate = moment(filters.date.startDate).format(
            "YYYY-MM-DD"
          );
        } else {
          this.selectedDate = null
        }

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "date,desc",
          search: this.search,
          custom: false,
          voucherTypeCodes: ["X", "M"],
          ...this.filters,
          date: this.selectedDate,
        };
        const response = await VoucherService.listDaily(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
