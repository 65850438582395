<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar inventario final</h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga de archivo .xlsx</h3>

          <button
            type="button"
            class="button-tooltip"
            v-b-tooltip.hover
            title="Descargar plantilla"
            @click="downloadTemplate"
          >
            <DownloadTableIcon />
          </button>
        </b-card-header>

        <b-card-body class="custom-card-body">
          <form class="custom-form" @submit.prevent="save">
            <div
              class="d-flex flex-column flex-md-row align-items-md-center w-50"
            >
              <label for="input-date" class="mb-0 mt-0 mr-3">Fecha</label>

              <FormDatepicker
                label-id="input-date"
                :show-calendar="showDatePicker"
                :min="$store.getters['app/getStartDate']"
                :max="$store.getters['app/getAvailableDate']"
                :hideHeader="false"
                :error="$v.selectedDate.$error"
                @show="showDatePicker = true"
                @hidden="showDatePicker = false"
                v-model="selectedDate"
                class="w-100"
              />

              <form-error
                message="Fecha de carga es requerida"
                v-if="$v.selectedDate.$error && !$v.selectedDate.required"
              ></form-error>
            </div>

            <div class="form-group">
              <file-input
                @onChange="onChange"
                idFile="final-batch-file"
                :acceptFile="['.xlsx']"
                ref="fileUpload"
              />

              <form-error
                message="El archivo es requerido"
                v-if="$v.file.$error && !$v.file.required"
              ></form-error>
            </div>

            <div class="form-group-buttons">
              <router-link
                :to="{ name: 'daily-final-batch-list' }"
                class="button button-light"
              >
                Cancelar
              </router-link>
              <button class="button button-primary" type="submit">
                Procesar
              </button>
            </div>

            <div class="form-errors mt-3" v-if="errors.length">
              <div class="table-responsive">
                <table class="custom-table w-100">
                  <thead>
                    <tr class="text-left">
                      <th>{{ `Errores: ${errors.length}` }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, i) in errors" :key="i">
                      <td class="pl-md-5">
                        <span
                          class="d-inline-block w-75"
                          >{{ e }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  Util,
  Alert,
  FormError,
  FormDatepicker,
  FileInput,
  DownloadTableIcon,
} from "wize-admin";

import fileDownload from "js-file-download";

import { FinalBatchService, DownloadService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FileInput,
    FormDatepicker,
    FormError,
    DownloadTableIcon,
  },
  data() {
    return {
      file: null,
      selectedDate: this.$store.getters["app/getAvailableDate"],
      errors: [],
      showDatePicker: false,
    };
  },
  validations: {
    file: { required },
    selectedDate: { required },
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
        date: this.selectedDate,
      });
      try {
        const payload = (await FinalBatchService.uploadDaily(data)).payload;
        if (!payload.success && payload.messages) {
          this.errors = payload.messages;
          return;
        }
        Alert.success("Se ha realizado la carga correctamente");
        this.$router.push({ name: "daily-final-batch-list" });
      } catch (ex) {
        console.error(ex);
        if (ex.errors && ex.errors.message) Alert.error(ex.errors.message);
        else
          Alert.error(
            "Ocurrió un error al procesar el archivo, revise que esté cargando el archivo correcto y pruebe nuevamente"
          );
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.dailyFinalBatchTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>
