<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Procesar Libro Diario</h1>
    </div>
    <div>
      <b-card tag="article" no-body class="custom-card custom-card--sm">
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Seleccione fecha</h3>
        </b-card-header>

        <b-card-body class="custom-card-body">
          <form class="custom-form" @submit.prevent="save">
            <div class="form-group">
              <label for="input-date">Fecha</label>
              <FormDatepicker
                label-id="selected-date"
                :show-calendar="showDatePicker"
                :min="$store.getters['app/getStartDate']"
                :max="$store.getters['app/getAvailableDate']"
                :hideHeader="true"
                :error="$v.selectedDate.$error"
                @show="showDatePicker = true"
                @hidden="showDatePicker = false"
                v-model="selectedDate"
                class="w-100"
              />
              <form-error
                message="Fecha de carga es requerida"
                v-if="$v.selectedDate.$error && !$v.selectedDate.required"
              ></form-error>

              <form-error
                message="Fecha es requerida"
                v-if="$v.selectedDate.$error && !$v.selectedDate.required"
              ></form-error>
            </div>
            <div class="form-group">
              <div class="d-flex">
                <b-form-checkbox id="clear-all-check" v-model="holiday">
                  <span class="font-weight-medium custom-checkbox-label"
                    >Día no laborable</span
                  >
                </b-form-checkbox>
              </div>
            </div>
            <div class="form-group-buttons text-right w-100">
              <router-link
                :to="{ name: 'daily-process-list' }"
                class="button button-light"
              >
                Cancelar
              </router-link>
              <button class="button button-primary" type="submit">
                Procesar
              </button>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, FormError, FormDatepicker } from "wize-admin";

import { ProcessService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      selectedDate: this.$store.getters["app/getAvailableDate"],
      holiday: false,
      showDatePicker: false,
    };
  },
  components: {
    FormDatepicker,
    FormError,
  },
  validations: {
    selectedDate: { required },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const params = { holiday: this.holiday, date: this.selectedDate };
      try {
        const payload = (await ProcessService.startProcess(params)).payload;
        if (!payload.success && payload.messages) {
          this.errors = payload.messages;
          return;
        }
        await this.$store.dispatch("app/loadAvailableDate");
        Alert.success("Ha iniciado el procesamiento del libro exitosamente");
        this.$router.push({ name: "daily-process-list" });
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>
